
.h-tick {
    display: flex;
    flex-direction: row;
    align-items: center;
    
}

.bit-of-space {
    padding-left: 4px;
}

.win-rev-abnb {
    margin-bottom: -100px;
    margin-top: 40px;
}

.win-rev-container {
    margin-top: 80px;
    margin-bottom: 80px;
}


.lit-bit-mar {
    margin-bottom: 120px;
}

.main-steam-book {
    color: #003953;
  
}

.just-some-mar {
    margin-top: 80px;
}

.margin-profile-foot {
    margin-top: -200px;
}


.tik-con, .tik-mar {
    margin-top: 100px;
}

.wrap-risk {
    margin-top: 50px;
}

.risk-but {
    margin-top: 50px;
}

.text-inperson {
    text-align: center;
}


.pro-marg {
    margin-bottom: -200px;
    margin-top: 50px;
    width: 100%;
}


.fixed-serv {
    font-size: 12px;
}


.icon-wrap-proccess {
    margin-bottom: 50px;
}

.book-price {
    margin-top: 50px;
}

.blurb-wrap {
    margin-top: 60px;
    margin-bottom: 60px;
}

.rec-deep {
    font-size: 12px;
    margin-bottom: 10px;
    margin-top: -5px;
}


.vid-wrapper-steam {
    margin-top: -152px;
    margin-bottom: -100px ;
    
}


.top-three {
    margin-bottom: 100px;
}


.rate-mar-steam {
    margin-top: -50px;
}

.mar-rev-steam {
    margin-bottom: 100px;
}


.mar-buttom-steam {
    margin-bottom: 50px;
}

.steam-booking-homepage {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.info-steam-sec {
    margin-bottom: 100px;
}

.rev-sec {
    margin-top: 100px;
    
}

.more-rev {
    margin-bottom: 100px;
}

.t-plus-c {
    font-size: 12px;
}


.margin-profile {
    margin-top: 100px;
}


.foot {
    margin-top: -200px;
}

.free-pre-vac-container {
    margin-bottom: -20px;
}


.free-vac {
    color: red;
}



.steam-rating {
    margin-bottom: 100px;
}


.review-content-bottom {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.gimage {
    height: 50px;
}

.booking-process {
    margin-bottom: 100px;
}

.review-review-steam {
    margin-top: 100px;
}


.flex-book-container {
    
    display: grid ;
    
}

.icon-heading-container {
  display: flex;
  align-items: center;
  margin-bottom: -20px;
}

.icon-heading-container svg {
  margin-right: 10px; /* Adjust the margin as needed */
}


.agent-clean {
    display: flex;
    justify-content: center;
    
    
}

.agent-heading {
    margin-left: 20px;
    font-size: 22px;
}


.white-box {
  background-color: white;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 5px;
  margin-top: 10px;
}

.form-wrapper-steam {
    margin-top: 100px;
}

.form-container-clean h4,
.form-container-clean p,
.form-container-clean input,
.form-container-clean textarea {
    font-family: 'Your Preferred Font', sans-serif; /* Adjust the font family */
    font-size: 16px; /* Adjust the font size */
}




.form-container-clean .input-contact-clean {
    margin-bottom: 10px; /* Adjust the margin as needed */
    color: #797979;
}


.tell-us-service  {
    margin-bottom: 10px; /* Adjust the margin as needed */
    color: #797979;
}

.selected-option, .price, .icon-heading-container, .clean-h4 {
    color: #797979;
}

 .price {
    font-weight: 800;
}

.color-price {
    color: #3AAFA9;
    font-size: 22px;
    margin-left: 20px;
}



.clean-heading {
    font-size: 36px;
    margin-bottom: 0px;
    color: #373e4a;
    margin-bottom: 5px;
}

.clean-h2 {
    color: #3AAFA9;
    font-size: 16px;
}

.form-clean-book {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: whitesmoke;
    height: 100%;
}


.form-one {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    width: 500px;
  
}

.form-clean {
    margin-top: 100px;
}

.form-container-clean {
    display: flex;
    flex-direction: column;
    padding: 20px;
}

.clean-h4 {
    margin-bottom: 0px;
    
}

.clean-p {
    color: #797979;

}

.number {
   text-decoration: none; 
   color: #3AAFA9;
}

.condition-p {
    font-size: 16px;

}

.clean-h4 {
    margin-bottom: 40px;
    margin-top: 40px;
}



.input-contact-clean {
    display: flex;
    
   
}

.input-select-width {
    width: 200px;
    height: 30px;
}

.tell-us-service {
    display: grid;
 
  
}

.hover-agent:hover {
    color: #3AAFA9;

}

.agent-color {
    color: #3AAFA9;
    font-weight: 800;

}

.qoute-button {
    color: whitesmoke;
    background-color: #3AAFA9;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 18px;
    border: none;
    border-radius: 10px;
    height: 40px;
    font-weight: 800;

}

.qoute-button:hover {
    color: whitesmoke;
    background-color: black;
}

.pay-later {
    font-weight: 200;
    font-size: 14px;
}

.li-check-items {
    list-style-type: none;
    color: #797979;
}


.result-msg { 
    font-weight: 900;
    margin-top: 20px;
}


.extra-icons {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 16px;
    margin-bottom: 8px;
}

.add-extras {
    margin-bottom: 5px;
}


.discount-box, .discount-button {
    font-weight: 900;
    color: grey;
}

.discount-button {
    border-radius: 10px;
    border: .5px solid;
    padding: 15px;
    background-color: #3AAFA9;
    color: whitesmoke;
}

.discount-button:hover {
    background-color: black;
    
}

.dicount-p {
    color: #3AAFA9;
    
}


.clean-heading-p {
    margin-top: 0px;
}

.book-later {
    max-width: 300px;
}

.select-p-option {
    font-weight: 600;
}


.rating-badge {
    height: 300px;
    margin-bottom: -100px;
}


.rating-img-clean {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    margin-top: -150px;
    height: 300px;
}



@media (max-width: 520px) {
.form-one {
   
    width: 400px;
  
}



.vid-wrapper-steam {
   margin-top: -300px;
    margin-bottom: -300px ;
    
}


}


@media (max-width: 425px) {
.form-one {
   
    width: 345px;
  
}

.clean-heading {
    font-size: 25px;
    
}


}


/* Other existing CSS rules */
