.rec-head-bond {
  font-size: 18px;
  margin-top: -20px;
  font-family: 'Lobster', cursive;

}


.p-description-partners-bnb {
  display: flex;
  flex-direction: row;
  align-items: center;
  

}

.rec-sec {
  margin-bottom: -40px;
}

.bnb-vid {


    max-width: 600px; /* Adjust size as needed */
    height: auto; /* Keep aspect ratio */
    object-fit: cover; /* Crop excess parts instead of stretching */
    border-radius: 10px; /* Optional: rounded corners */

}

.head-window-top {
  font-size: 44px;
  font-family: 'Lobster', cursive;
  color: #003953;
}

.why-heading {
  font-size: 44px;
  color: #003953;
}


.window-p {
  border: 2px solid ;
}

.mar-p-gym {
  margin-top: 40px;
}



.third-end-wrap {
  margin-right: 15px;
}


.end-three {
  margin-top: 50px;
}


.rec-head {
  margin-top: 50px;
  font-family: 'Lobster', cursive;
    color: #003953;
    font-size: 44px;
}

.not-sure {
  color: #003953;
}

.p-description {
  color: rgb(105, 100, 100);
}


.carpet-room {
  border-radius: 30px;
  
}


.finger {
  font-size: 25px;
}

.custom-p {
  margin-top: 35px;
  
   
}
  
  


.logo-agent {
  height: 30px;
}



.p-description-partners {
  margin-top: 80px;
}

.why-us-container {
  margin-top: 150px;
}


.carpet-wrapper-two {
  background-color: white;
}

.carpet-wrapper {
    display: flex;
    flex-direction: row;
    background-color: whitesmoke;
 
   
}

.carpet-description h6 {
  font-size: 18px;
  line-height: 1.5;
  font-weight: 400;
  
}

.p-description {
  font-size: 18px;
}


.carpet-wrapper {
  padding: 20px;
}

.carpet-description {
  margin-left: 20px;
  margin-top: -20px;
}

.p-description {
  margin-bottom: 10px;
}


.image-carpet img {
  max-width: 100%;
  max-height: auto;
}

.p-description {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.p-description svg {
  margin-right: 10px; /* Adjust the margin between the icon and text as needed */
}

.rating-img {
    display: flex;
    position: absolute;
    top: 250px;
    left:  0;
    height: 250px;
}



.end-lease-link {
   
    color: whitesmoke;
    font-weight: 600;
    background-color: #3AAFA9;
    width: 300px;
    justify-content: center;
    height: 50px;
    border-radius: 10px;
    font-size: 18px;
}

.end-lease-link:hover  {
    
    background-color: black;
    
}



.click-space {
    margin-left: 5px;
}

.click-space:hover {
    color: black;
}




@media (min-width: 768px) {
  .image-carpet img {
    width: 700px; /* Set the desired width for desktop screens */
    max-width: none; /* Remove the max-width restriction */
  }
.tick-gen-clean {
  display: none;
  
}
  
}

@media (max-width: 768px) {
  .carpet-wrapper {
    flex-direction: column;
    align-items: center;
  }

  .p-description {
  font-size: 16px;
}

.check-icon {
  display: none;
}

.tick-gen-clean {
  margin-right: 5px;
  
}

 
}


@media (max-width: 550px) {
  .oven-three {
    margin-top: 40px;
  
  
}

.head-window-top, .rec-head {
  font-size: 36px;
 
}
  




 
}


@media (max-width: 480px) {
  .rating-img {
    
    top: 180px;
    left:  -20px;
    height: 200px;
}


.header-us {
  font-size: 26px;
  font-weight: 900;
  color: #3AAFA9;
}




 
}




